<template>
    <div class="d-flex flex-wrap">
        <div class="col-12 text-center text-second p-4">
            <h1>
                {{ $t('Travels.Inspection.header.title') }} <br>
                {{ $t('Travels.Inspection.header.subtitle') }}
            </h1>
        </div>
        <div class="col-12 border-third rounded-5 iframe-wrapper">
            <IFrame @decoded_res="decoded_res" v-if="IFrame"/>
        </div>
        <div class="col-12 text-center mt-4">
            <p class="m-0">
                <span class="fw-bold text-danger">
                    {{ $t('Travels.Inspection.proof.proof_of_purchase') }}
                </span>
                {{ $t('Travels.Inspection.proof.notice_1') }} <br>
                {{ $t('Travels.Inspection.proof.notice_2') }}
            </p>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import IFrame from '@/components/IFrame.vue'
export default {
    name: 'InspectionView',
    components: {
        IFrame
    },
    computed: {
        ...mapState(['config', 'IFrame']),
        ...mapGetters(['ApiPath', 'ApiKey']),
    },
    methods: {
        ...mapMutations(['setLoader', 'setCredentials']),
        decoded_res(decoded_res) {
            this.getCardAccounts(decoded_res.pan_token)
        },
        getCardAccounts(pan_token) {
            this.setLoader(true)
            var token_to_use
            if (this.config.use_fake_token) {
                token_to_use = this.config.fake_token
            } else {
                token_to_use = pan_token
            }

            this.$http
                .post(`${this.ApiPath}/cardAccount`, { serverToken: token_to_use },
                {
                    headers: {
                        'api-key': this.ApiKey
                    }
                })
                .then((res) => {
                    this.setCredentials({
                        token: pan_token,
                        cardAccounts: res.data.cardAccounts
                    })
                    this.setLoader(false)
                    this.$router.replace('/journeys')
                })
                .catch(() => {
                    console.log(`No CardAccount found`)
                    this.setCredentials({
                        token: pan_token,
                        cardAccounts: []
                    })
                    this.setLoader(false)
                    this.$router.replace('/journeys')
                })
        }
    }
}
</script>

<style lang="scss" scoped>
</style>