<template>
    <div class="d-flex flex-wrap">
        <div class="col-12">
            <h1 class="text-first text-center">
                <i class="fa-solid fa-route me-2"></i>
                {{ $t(`Travels.${this.$route.name}.RouteName`) }}
            </h1>
        </div>
        <div class="col-12 my-5 border-third rounded-5 p-4 bg-white" v-if="!loader && journeys">
            <DataTable :date="selectedDate" :tbody="journeys" @filterByDate="filterByDate"/>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import moment from 'moment'
import DataTable from '../../components/DataTables/JourneysDataTable.vue'
export default {
    name: 'JourneysView',
    components: {
        DataTable
    },
    computed: {
        ...mapState(['loader', 'credentials']),
        ...mapGetters(['ApiPath', 'ApiKey'])
    },
    data() {
        return {
            selectedDate: null,
            journeys: null
        }
    },
    methods: {
        ...mapMutations(['setLoader']),
        getJourneys(startDate, endDate) {
            this.setLoader(true)

            if (!startDate) {
                startDate = moment().subtract(29, 'days').set({hour:0,minute:0,second:0}).format()
            }
            if (!endDate) {
                endDate = moment().set({hour:23,minute:59,second:59}).format()
            }

            this.$http
                .post(`${this.ApiPath}/getJourneys`,
                {
                    cardAccounts: this.credentials.cardAccounts,
                    startDate,
                    endDate
                },
                {
                    headers: {
                        'api-key': this.ApiKey
                    }
                })
                .then((res) => {
                    this.selectedDate = `${moment(startDate).format('DD/MM/YYYY')} - ${moment(endDate).format('DD/MM/YYYY')}`
                    this.journeys = res.data
                    this.setLoader(false)
                })
                .catch((err) => {
                    console.log(err)
                })

        },
        async filterByDate(when) {
            this.journeys = null
            this.journeys = await this.getJourneys(when.startDate, when.endDate)
        }
    },
    created() {
        this.getJourneys()
    }
}
</script>