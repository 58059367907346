<template>
    <div class="d-flex justify-content-center align-items-start flex-wrap receipt-backdrop p-4" @click.self="$emit('close')">
        <div class="col-12 d-flex flex-wrap rounded-5 p-4 border border-third bg-second">
            <div class="col-12 d-flex justify-content-between align-items-center">
                <div class="col-6">
                    <h1 class="text-first">
                        <i class="fa-solid fa-file-invoice-dollar me-2"></i>
                        {{ $t(`Travels.${this.$route.name}.PaymentReceipt.header`) }}
                    </h1>
                </div>
                <div class="col-6 text-end">
                    <h2 class="text-fifth" role="button" @click="$emit('close')">
                        <i class="fa-solid fa-xmark"></i>
                    </h2>
                </div>
            </div>
            <div class="col-12 d-flex justify-content-center justify-content-xl-end my-4">
                <h2 class="btn btn-lg btn-auth btn-pulse me-2" @click="$emit('download')">
                    <i class="fa-solid fa-download"></i>
                    {{ $t(`Travels.${this.$route.name}.PaymentReceipt.download`) }}
                </h2> 
            </div>
            <div class="col-12 d-flex flex-wrap border bg-white p-4" id="receipt">
                <div class="col-12 text-center">
                    <img :src="require(`@/assets/img/tenants/${ApiKey}/${ApiKey}.jpg`)">
                </div>
                <div class="col-12 text-center mt-3 py-3 receipt-header">
                    <h4 class="fw-bold font-monospace">
                        {{ $t(`Travels.${this.$route.name}.PaymentReceipt.receiptTitle`) }}
                    </h4>
                </div>
                <div class="col-12 text-center py-3">
                    <h4 class="font-monospace">
                        {{ currentTimeStamp }}
                    </h4>
                </div>
                <div class="col-12 d-flex flex-wrap">
                    <div class="col-12 d-flex align-items-center flex-wrap mb-2">
                        <div class="col-6">
                            <span class="font-monospace fw-bold">
                                {{ $t(`Travels.${this.$route.name}.PaymentReceipt.labels.transactionId`) }}:
                            </span>
                        </div>
                        <div class="col-6">
                            <span class="font-monospace">
                                {{ receipt.transactionId }}
                            </span>
                        </div>
                    </div>
                    <div class="col-12 d-flex align-items-center flex-wrap mb-2">
                        <div class="col-6">
                            <span class="font-monospace fw-bold">
                                {{ $t(`Travels.${this.$route.name}.PaymentReceipt.labels.bankApplicationId`) }}:
                            </span>
                        </div>
                        <div class="col-6">
                            <span class="font-monospace">
                                {{ receipt.bankApplicationId }}
                            </span>
                        </div>
                    </div>
                    <div class="col-12 d-flex align-items-center flex-wrap mb-2">
                        <div class="col-6">
                            <span class="font-monospace fw-bold">
                                {{ $t(`Travels.${this.$route.name}.PaymentReceipt.labels.paymentId`) }}:
                            </span>
                        </div>
                        <div class="col-6">
                            <span class="font-monospace">
                                {{ receipt.paymentId }}
                            </span>
                        </div>
                    </div>
                    <div class="col-12 d-flex align-items-center flex-wrap mb-2">
                        <div class="col-6">
                            <span class="font-monospace fw-bold">
                                {{ $t(`Travels.${this.$route.name}.PaymentReceipt.labels.lastTapDate`) }}:
                            </span>
                        </div>
                        <div class="col-6">
                            <span class="font-monospace">
                                {{ this.$formatDate(receipt.lastTapDate, session_language, Intl.DateTimeFormat().resolvedOptions().timeZone) }}
                            </span>
                        </div>
                    </div>
                    <div class="col-12 d-flex align-items-center flex-wrap mb-2">
                        <div class="col-6">
                            <span class="font-monospace fw-bold">
                                {{ $t(`Travels.${this.$route.name}.PaymentReceipt.labels.acceptorSign`) }}:
                            </span>
                        </div>
                        <div class="col-6">
                            <span class="font-monospace">
                                {{ receipt.acceptorSign }}
                            </span>
                        </div>
                    </div>
                    <div class="col-12 d-flex align-items-center flex-wrap mb-2">
                        <div class="col-6">
                            <span class="font-monospace fw-bold">
                                {{ $t(`Travels.${this.$route.name}.PaymentReceipt.labels.acceptorId`) }}:
                            </span>
                        </div>
                        <div class="col-6">
                            <span class="font-monospace">
                                {{ receipt.acceptorId }}
                            </span>
                        </div>
                    </div>
                    <div class="col-12 d-flex align-items-center flex-wrap mb-2">
                        <div class="col-6">
                            <span class="font-monospace fw-bold">
                                {{ $t(`Travels.${this.$route.name}.PaymentReceipt.labels.siret`) }}:
                            </span>
                        </div>
                        <div class="col-6">
                            <span class="font-monospace">
                                {{ receipt.siret }}
                            </span>
                        </div>
                    </div>
                    <div class="col-12 d-flex align-items-center flex-wrap mb-2">
                        <div class="col-6">
                            <span class="font-monospace fw-bold">
                                {{ $t(`Travels.${this.$route.name}.PaymentReceipt.labels.applicationCryptogram`) }}:
                            </span>
                        </div>
                        <div class="col-6">
                            <span class="font-monospace">
                                {{ receipt.applicationCryptogram }}
                            </span>
                        </div>
                    </div>
                    <div class="col-12 d-flex align-items-center flex-wrap mb-2">
                        <div class="col-6">
                            <span class="font-monospace fw-bold">
                                {{ $t(`Travels.${this.$route.name}.PaymentReceipt.labels.acceptationPointNum`) }}:
                            </span>
                        </div>
                        <div class="col-6">
                            <span class="font-monospace">
                                {{ receipt.acceptationPointNum }}
                            </span>
                        </div>
                    </div>
                    <div class="col-12 d-flex align-items-center flex-wrap mb-2">
                        <div class="col-6">
                            <span class="font-monospace fw-bold">
                                {{ $t(`Travels.${this.$route.name}.PaymentReceipt.labels.saccTransactionNum`) }}:
                            </span>
                        </div>
                        <div class="col-6">
                            <span class="font-monospace">
                                {{ receipt.saccTransactionNum }}
                            </span>
                        </div>
                    </div>
                    <div class="col-12 d-flex align-items-center flex-wrap mb-2">
                        <div class="col-6">
                            <span class="font-monospace fw-bold">
                                {{ $t(`Travels.${this.$route.name}.PaymentReceipt.labels.last4Digits`) }}:
                            </span>
                        </div>
                        <div class="col-6">
                            <span class="font-monospace">
                                {{ receipt.last4Digits }}
                            </span>
                        </div>
                    </div>
                    <div class="col-12 d-flex align-items-center flex-wrap mb-2">
                        <div class="col-6">
                            <span class="font-monospace fw-bold">
                                {{ $t(`Travels.${this.$route.name}.PaymentReceipt.labels.bankingTransactionType`) }}:
                            </span>
                        </div>
                        <div class="col-6">
                            <span class="font-monospace">
                                {{ this.$formatTransactionType(receipt.bankingTransactionType) }}
                            </span>
                        </div>
                    </div>
                    <div class="col-12 d-flex align-items-center flex-wrap mb-2">
                        <div class="col-6">
                            <span class="font-monospace fw-bold">
                                {{ $t(`Travels.${this.$route.name}.PaymentReceipt.labels.amount`) }}:
                            </span>
                        </div>
                        <div class="col-6 d-flex align-items-center flex-wrap">
                            <div class="col-4">
                                <span class="font-monospace">
                                    {{ this.$formatAmount(session_language, receipt.amount, receipt.currencyCode) }}
                                </span>
                            </div>
                            <div class="col-4">
                                <span class="font-monospace fw-bold">
                                    {{ $t(`Travels.${this.$route.name}.PaymentReceipt.labels.vatAmount`) }}:
                                </span>
                                <span class="font-monospace">
                                    {{ this.$formatAmount(session_language, receipt.vatAmount, receipt.currencyCode) }}
                                </span>
                            </div>
                            <div class="col-4">
                                <span class="font-monospace fw-bold">
                                    {{ $t(`Travels.${this.$route.name}.PaymentReceipt.labels.vatRate`) }}:
                                </span>
                                <span class="font-monospace">
                                    {{ receipt.vatRate }}
                                    <i class="fa-solid fa-percent"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    name : 'ReceiptComp',
    props: {
        currentTimeStamp: String,
        receipt: Object
    },
    computed: {
        ...mapGetters(['ApiKey'])
    },
    data() {
        return {
            session_language: localStorage.getItem('session_language')
        }
    }
}
</script>

<style lang="scss" scoped>

    img {
        width: 35%;
    }

    .receipt-header {
        border: 4px dotted black;
        border-left: 0;
        border-right: 0;
    }

    span {
        font-size: 12px;
    }
</style>