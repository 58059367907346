<template>
    <Loader v-if="loader"/>
    <Header/>
    <main class="p-3 p-md-5">
        <router-view/>
    </main>
    <Footer/>
    <Version/>
</template>

<script>
import $ from 'jquery'
import { mapState, mapGetters } from 'vuex'
import Loader from '@/components/Loader.vue'
import Header from './components/Header.vue'
import Footer from './components/Footer.vue'
import Version from '@/components/Version.vue'
export default {
    name: 'ILk_Vj-29lw8lI6XnQ3lq',
    components: {
        Loader,
        Header,
        Footer,
        Version
    },
    computed: {
        ...mapState(['config', 'loader']),
        ...mapGetters(['ApiKey'])
    },
    methods: {
        setFavicons(favImg) {
            let headTitle = document.querySelector('head')
            let setFavicon = document.createElement('link')
            setFavicon.setAttribute('rel', 'icon')
            setFavicon.setAttribute('href', favImg)
            headTitle.appendChild(setFavicon)
        }
    },
    created() {
        document.title = this.config.tenant
        this.setFavicons(`/${this.ApiKey}.ico`)
        $('body').addClass(`${this.config.env}`)
        this.$store.dispatch('autoSetCredentials')
    }
}
</script>